import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, details } from "../action";


export async function getGroupChatDetails(dispatch, getUser) {
    dispatch(isLoading());
    console.log(getUser)
    try {
      var response = await API.post("group_chat.php", {
        action :'getGroupDetails',
        created_by: getUser.userName,
        projectId: getUser.projectId,
        corp_code: getUser.corp
        // "userType": getUser.role,
        // "empId": getUser.empId,
      }, {}, false);
      console.log(response)
      if (response.status === 'True') {
        dispatch(details(response.data))
      }
      else {
        dispatch(details([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }