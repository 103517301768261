import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";
import { getEmployeesRoles } from "./getEmployeeRoles";

export async function updateFirstUser(state, dispatch, getUser, APP_NAME) {
    console.log(state.userType.value)
    dispatch(isLoading());
    if ((
      state.userType.value !== "")) {
      // && state.roleSelected.value !== "" && state.designationSelected.value !== ""
      try {
        var response = await API.post("manage_user.php", {
          action: "update_role",
          corp: getUser.corp,
          empId:getUser.empId,
          projectId:getUser.projectId,
          password: state.password.value,
          userType: state.userType.value,
          url: Client_URL,
          appName: APP_NAME,
        }, {}, false);
        if (response.status === "True") {
          Alert("success", response.message)   
          getEmployeesRoles(dispatch,getUser)      
        } else {
          Alert("warning", response.message)
        }
      } catch (error) {
        Alert('error', error.message);
      }
    } else {
      Alert("warning", "please fill all the details")
    }
    dispatch(isLoaded());
  }