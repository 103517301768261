//release environment (development)
export const Server_URL = 'https://api-single.agile24x7.com/task_pg_release'; 
export const Reports_URL = 'https://pgtestreporting.agile24x7.com';
export const Client_URL = 'https://test.wetasker.com/#/';
export const Meet_URL = 'rally.wetasker.com';
export const ChatBot_URL = "https://chatbot.agile24x7.com"

//preprod environment (preprod)
//  export const Server_URL = 'https://api-single.agile24x7.com/task24x7_preprod'; 
//  export const Reports_URL = 'https://pgreportingpreprod.agile24x7.com';
//  export const Client_URL = 'https://preprod-wetasker.task24x7.com/#/'; 
//  export const Meet_URL = 'rally.novisync.com';
//  export const ChatBot_URL = "https://chatbot.agile24x7.com"

//production envrironment (production)
// export const Server_URL = 'https://api-single.agile24x7.com/task_pg_prod'; 
// export const Reports_URL = 'https://pgreporting.agile24x7.com';
// export const Client_URL = 'https://wetasker.com/#/';
// export const Meet_URL = 'rally.novisync.com';
// export const ChatBot_URL = "https://chatbot.agile24x7.com"
