import API from "../../../Common/Network/API";
import { isLoading, isLoaded} from "../action";
import Alert from '../../../Common/Alert';

// for update question
export async function updateFaq(state, dispatch, handleClose) {
    // console.log("state",state)
    dispatch(isLoading()); 
      try {
          var response = await API.post("faqs.php", {
          action: "update",
          ques: state.ques.value,
          ans: state.ans.value,
          id: state.id.value,
        }, {}, false);
        if (response.status === 'True') {
          Alert("success", "Updated successfully!");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    dispatch(isLoaded());
  }  