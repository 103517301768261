export const defaultWebProps = {
  "APP_NAME": "Task24x7",
  "SUPPORT_EMAIL": "support@task24X7.com",
  "CONTENT1": "Software teams that embrace task project management methodologies increase their development speed, expand collaboration, and foster the ability to better respond to market trends.",
  "SUB_CONTENT1": "We help organize your work, so your team can strike a healthy balance between structure, flexibility, and launching Products according to the client need on time.",
  "IMAGE1": "IMAGE1",
  "IMAGE_CONTENT1": "Task 24X7 is the software that handles any Sprint, User Story without any confusion.",
  "IMAGE_SUB_CONTENT1": "Manage your sprint planning, User Stories, and tasks with Task 24X7 and keep everyone in-the-loop.",
  "IMAGE2": "IMAGE2",
  "IMAGE_CONTENT2": "Manage iterations and task workflows all in one place Release quality products faster, together",
  "IMAGE_SUB_CONTENT2": "With all communication and updates in one place, work better together to push the product forward.",
  "IMAGE3": "IMAGE3",
  "IMAGE_CONTENT3": "Epics, Sprint, User Stories, Backlogs",
  "IMAGE_SUB_CONTENT3": "These simple structures help task teams gracefully manage scope and structure work. With all communication and updates in one place, your team work better together to push the product forward.",
  "IMAGE4": "IMAGE4",
  "IMAGE_CONTENT4": "Better User experience with Easy To Use Dashboard Stay on top of your sprints",
  "IMAGE_SUB_CONTENT4": "Use dashboards to easily track User stories and backlogs to see where your team stands against each EPIC.",
  "IMAGE_SLIDER": "IMAGE_SLIDER",
  "FOOTER_CONTENT1": "Try Task 24x7 for your team",
  "FOOTER_SUB_CONTENT1": "Sign up and look who is using Task 24x7 to get their work done."
}