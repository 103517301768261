import {get_shifts } from "../action";
import API from "../../../Common/Network/API";

export async function getShifts(dispatch){
    try {
        const response = await API.post("get_user_shifts.php", { action: "get_shifts" }, {}, false);
        if (response.status === "True") {
            dispatch(get_shifts(response.data))
        } else {
            dispatch(get_shifts([]))
        } 
    } catch (error) {
    }
}