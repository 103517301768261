import API from "../../../Common/Network/API";
import { isLoading, isLoaded } from "../action";
import Alert from '../../../Common/Alert';

 // for delete  question
 export async function deleteFaq(dispatch, id, handleClose) {
    dispatch(isLoading());
      try {
        var response = await API.post("faqs.php", {
          action: "delete",
          id: id,
        }, {}, false);
        if (response.status === 'True') {
          Alert("success", "Deleted successfully!");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
  }