import API from "../../../Common/Network/API";
import { isLoading, isLoaded, appDetails } from "../action";
import Alert from '../../../Common/Alert';

// for getting all faqs
export async function getDetails(dispatch) {
    dispatch(isLoading());
    try {
      var response = await API.post("faqs.php",{
        action: 'getDetails',
      }, {}, false);
      if(response.status === 'True'){
        dispatch(appDetails(response.data));
      }
    } catch (error) {
        console.log(error)
      Alert(error.message)
    }
    dispatch(isLoaded());
  }