import {  isLoaded, isLoading,  rolesList } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";


//For Get Roles For First User
export async function getEmployeesRoles(dispatch, getUser, APP_NAME) {
    dispatch(isLoading());
    try {
      var response = await API.post("manage_user.php", {
        "corp": getUser.corp,
        "action":'get_user_role',
        "empId":getUser.empId,
        "projectId":getUser.projectId,
        url: Client_URL,
        appName: APP_NAME,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(rolesList(response.data))
      }
      else {
        // dispatch(employees([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }