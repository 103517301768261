import API from "../../../Common/Network/API";
import { isLoaded, isLoading, properties } from "../actions";
import { setToken } from '../../../Common/LocalStorage';
import store from '../../../Common/Store';

const defaultAppProperties = {
  "MODULES": "Sprint",
  "MAINTASKS": "User Story",
  "SUBTASKS": "Task",
  "PROJECT": "Epic",
  "KANBAN": "Kanban board",
  "KANBAN_NAME": "Kanban Name",
  "BACKLOG": "Backlog",
  "WHITEBOARD": "WhiteBoard",
  "AGILE": "Agile",
  "USER_STORIES": "User Stories",
  "ROADBLOCKS": "Roadblock",
  "ARCHIVE": "Archive",
  "STORY_POINTS": "Story Points",
  "SPRINT_CYCLE": "Sprint Cycle",
  "ACCEPTANCE_CRITERIA": "Acceptance Criteria",
  "SQUAD": "Squad",
  "PRIORITY_LEVEL": "Priority Level",
  "SPRINT_VELOCITY": "Sprint Velocity",
  "BURN_UP_REPORT": "Burn Up Report",
  "BURN_DOWN_REPORT": "Burn Down Report",
  "INDIVIDUAL_VELOCITY": "Individual Velocity",
  "EPIC_VELOCITY": "Epic Velocity",
  "INDIVIDUAL_WORKLOAD": "Individual Workload",
  "AGILE_PROJECT_NAME": "Agile Project Name",
  "IMG_SRC": "images/common/agile.png",
  "SCRUM_BOARD": "Scrum-Board",
  "ACTIVE_SPRINT": "Active Sprint",
  "COMPLETED_EPICS": "Completed Epics",
  "CONTRIBUTOR": "Contributor",
  "PRODUCT_OWNER": "Product Owner",
  "SCRUM_MASTER": "Scrum Master",
  "USERS_ROADBLOCKS": "Users Road Blocks",
  "ROADBLOCK_PERCENTAGE": "Roadblock Percentage",
  "AGILE_SQUAD": "Agile Squad",
  "USAGE_REPORTS": "Agile Usage Reports",
  "USER_EFFICIENCY": "Employee Efficiency"
}

export async function getProps(state, dispatch) {

    dispatch(isLoading());
    try {
      var response = await API.post("properties.php", {
        "action": "getProps",
        "app_name": window.location.origin,
        "properties": JSON.stringify(defaultAppProperties),
        "prop_type": "app_props"
      }, {}, false);
      setToken('properties', response.data.properties)
      store.dispatch(properties(JSON.parse(response.data.properties))); //store the user information
    } catch (error) {
      // Alert('error',error.message);
    }
    dispatch(isLoaded());
  }