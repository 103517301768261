import { useDispatch, useSelector } from "react-redux"
import React, { useReducer, useEffect, useState } from "react"
import { consolidatedToDoReducer, initialState } from "./reducer"
import { getActiveUserStory } from "./Services/getActiveUserStory";
import { getConsolidatedTodo } from "./Services/getConsolidatedToDo";
import { getPrivateTodo } from "./Services/getPrivateToDo";
import { updateUserStory } from "./Services/updateUserStory";
import $ from 'jquery';
import SideBar from "../SquadChat/sideNav"
import TopNavWithOutProject from "../Utility/TopNav/topnav"
import { getSubStringId } from "../../Common/SubStringConvert"
import MainTaskInfo from "../../Common/TasksModals/mainTaskInfo"
// import ModifyTemplate from "../../Common/TasksModals/modifyTemplate"
// import DeleteTemplate from "../../Common/TasksModals/deleteTemplate"
// import EditTemplate from "../../Common/TasksModals/editTemplate"
import AddPrivateToDo from "../../Common/PrivateToDo/addPrivateToDo"
import AddToToDoProjects from "../../Common/PrivateToDo/addToDoToProject"
import ModifyPrivateToDo from "../../Common/PrivateToDo/modifyPrivateToDo"
import DeletePrivateToDo from "../../Common/PrivateToDo/deletePrivateToDo"
import { getRoleCount, setRoleCount, setToken } from "../../Common/LocalStorage"
import { setCurrentUser } from "../../Common/Actions"
import API from "../../Common/Network/API"
import jwt_decode from "jwt-decode";
import { Redirect, Link } from "react-router-dom"
import moment from "moment"
import { useWebProperties } from "../../Common/webProperties";
import { moduleReducer, initialState as chatInitialState } from "../Modules/moduleReducer";
import MainTaskChatBox from "../../Common/ChatMainTask";
import ModifyMainTask from "../../Common/TasksModals/modifyMainTask";
import AddSubTask from "../../Common/SubTaskModals/addSubTask";
import MainTaskDelete from "../../Common/TasksModals/deleteMainTask";
import MainTaskVerify from "../../Common/TaskVerify/mainTaskVerify";
import AddToSprint from "../Backlogs/addToSprint";
import ChangeUserstoryStatus from "../Modules/changeUserstoryStatus";
import RoadBlock from "../../Common/RoadBlock";
// import { getAllTaskMessages } from "../Modules/network";
import { getAllUserStoryMessages } from "../Modules/Services/getAllUserStoryMessages";

export default function ConsolidatedTodoList() {
    const getUser = useSelector(state => state.auth);
    const dispatch1 = useDispatch();
    const [state, dispatch] = useReducer(consolidatedToDoReducer, initialState)
    const [chatState, dispatch2] = useReducer(moduleReducer, chatInitialState)
    const [searchWord, setSearchWord] = useState('')
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [redirections, setRedirections] = useState({ action: null, redirect: false, status: null });
    const { ACTIVE_SPRINT, CONSOLIDATED_TODO, MAINTASKNAME, PRIVATE_TODO, STORY_POINTS, PRIORITY_LEVEL, CHAT, ADD_TO_EPIC,
        MANAGEPROJECTS, PRODUCT_OWNER, SCRUM_MASTER, NEWSUBTASK, EDIT, ADD_TO_SPRINT, MAINTASK, VIEWSUBTASKS, KANBAN, role_array,
        AGILE_PROJECT_NAME, PROJECTNAME, ADD_PRIVATE_TODO, ACTION_ICON, MODULES, PENDING_SUBTASKS, ROADBLOCKS, KANBAN_NAME } = useWebProperties();
    // const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);

    var date = moment();
    var currentDate = date.format('YYYY-MM-D');
    const pst = currentDate.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });

    useEffect(() => {
        getAllUserStoryMessages(dispatch2, getUser.user);
        getConsolidatedTodo(dispatch, getUser.user);
        getPrivateTodo(dispatch, getUser.user);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (state.consolidatedToDo.length >= 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                })
            })
        }
    }, [state.consolidatedToDo])

    const SquadChangeStatus = async (project_id, project_name, sprintActiveStatus, action) => {
        try {
            const response = await API.post("squad_login.php", {
                username: getUser.user.empId,
                project_id: project_id,
                corp: project_name,
            }, {}, false);
            if (response.status === 'TRUE') {
                setToken('auth', response.jwt) //store the token information  with exp
                const tokenDetails = jwt_decode(response.jwt);
                setRoleCount('roleCount', tokenDetails.data.roleCount)
                dispatch1(setCurrentUser(tokenDetails.data)); //store the user information
                setRedirections({ action, redirect: true, status: sprintActiveStatus })//checking for Active sprint or not to redirects to sprint board/backlogs board
            }
        } catch (error) {
            // UpdateUserSquad(getUser.user.corp)
        }
        // dispatch(isLoaded());
    }

    const getMessagesCount = (data, msg, task, empId) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task.us_id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return (
            <i>
                {msgCount.length > 0 ?
                    <div style={{ display: 'flex', marginRight: '-8px' }}>
                        <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                            onClick={(event) => handleChatOpen("maintask", data, msgCount)} />
                        <span style={{ color: 'red', fontWeight: "bold", marginTop: '-4px' }}>{msgCount.length}</span>
                    </div>
                    :
                    <div style={{ display: 'flex' }}>
                        <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                            onClick={(event) => handleChatOpen("maintask", data, msgCount)} />
                    </div>}
            </i>
        )
    };

    const handleChatOpen = (action, index, sno) => {
        var info;
        setOpen({ status: true, action: action });
        if (action === "maintask") {
            info = {
                action: action, id: state.consolidatedToDo[index].us_id, sno: sno, project_name: state.consolidatedToDo[index].project_name, project_id: state.consolidatedToDo[index].project_id,
            }
        }
        setCardInfo(info);
    }

    const handleChatClose = () => {
        setOpen({ status: false, index: 0 })
        getAllUserStoryMessages(dispatch2, getUser.user);
    };

    const handleOpen = (action, index, data) => {
        var info
        setOpen({ status: true, index: index, action: action });
        if (action === "taskInfo") {
            var view_status = "taskInfo"
            info = {
                view: view_status,
                us_id: state.consolidatedToDo[index].us_id,
                projectName: state.consolidatedToDo[index].project_name,
                epicId: state.consolidatedToDo[index].epic_id,
                moduleName: state.consolidatedToDo[index].sprint_desc,
                moduleId: state.consolidatedToDo[index].sprint_id,
                title: state.consolidatedToDo[index].story_title,
                description: state.consolidatedToDo[index].story_desc,
                taskId: state.consolidatedToDo[index].story_id,
                createdDate: state.consolidatedToDo[index].created_date,
                taskProgress: state.consolidatedToDo[index].story_status,
                storyPoints: state.consolidatedToDo[index].story_points,
                acceptanceCriteria: state.consolidatedToDo[index].acceptance_criteria,
                assignedTo: state.consolidatedToDo[index].assignedto,
                assignedBy: state.consolidatedToDo[index].assignedby,
                completedDate: state.consolidatedToDo[index].modified_date,
                completedStatus: state.consolidatedToDo[index].complete_status,
                targetDate: state.consolidatedToDo[index].target_date,
                device_id: state.consolidatedToDo[index].device_id,
                project_name: state.consolidatedToDo[index].project_name,
            }
        }
        else if (action === "addToProject") {
            var status = "backlog_addUser"
            info = { view: status, data: data }
        } else if (action === "modify") {
            status = "modify"
            info = { view: status, data: data }
        }
        else if (action === "delete") {
            status = "delete"
            info = { view: status, data: data }
        }
        else if (action === "complete") {
            status = "complete"
            info = { view: status, data: data }
        } else if (action === "private_todo_taskInfo") {
            view_status = "private_todo_taskInfo"
            info = {
                view: view_status,
                pt_id: state.privateToDo[index].pt_id,
                priority_level: state.privateToDo[index].priority_level,
                title: state.privateToDo[index].story_title,
                description: state.privateToDo[index].story_desc,
                storyPoints: state.privateToDo[index].story_points,
                acceptanceCriteria: state.privateToDo[index].acceptance_criteria,
                assignedBy: state.privateToDo[index].username,
                personal_todo_status: state.privateToDo[index].personal_todo_status,
            }
        } else if (action === "addSubtask") {
            info = {
                mainTaskId: state.consolidatedToDo[index].story_id,
                action: action, moduleId: state.consolidatedToDo[index].sprint_id,
                ideaId: state.consolidatedToDo[index].project_id,
                assignedTo: state.consolidatedToDo[index].assignedTo,
                us_id: state.consolidatedToDo[index].us_id,
                title: state.consolidatedToDo[index].story_title,
                projectName: state.consolidatedToDo[index].project_name,
            }
        }
        else if (action === "modifyTask") {
            info = {
                moduleId: state.consolidatedToDo[index].sprint_id,
                moduleName: state.consolidatedToDo[index].sprint_desc,
                priorityLevel: state.consolidatedToDo[index].priority_level,
                projectId: state.consolidatedToDo[index].project_id,
                ideaId: state.consolidatedToDo[index].epic_id,
                projectName: state.consolidatedToDo[index].epic_name,
                title: state.consolidatedToDo[index].story_title,
                description: state.consolidatedToDo[index].story_desc,
                taskId: state.consolidatedToDo[index].story_id,
                acceptanceCriteria: state.consolidatedToDo[index].acceptance_criteria,
                storyPoints: state.consolidatedToDo[index].story_points,
                userDetails: state.consolidatedToDo[index].assignedto,
                id: state.consolidatedToDo[index].assignedTo,
                targetDate: state.consolidatedToDo[index].target_date,
            }
        }
        else if (action === "add") {
            status = "backlog_addUser"
            info = {
                view: status,
            }
        }
        else if (action === "changeUserstoryStatus") {
            info = {
                action: action,
                inprogress: data.inprogressStoryId,
                todo: data.todoStoryId,
                currentSprint: data.currentSprint,
                assignedTo: data.assignedTo,
                projectName: data.projectName,
                story_title: data.story_title,
                inprogress_story_title: data.inprogress_story_title,
                us_id: data.us_id,
                todo_us_id: data.todo_us_id
            }
        }
        else if (action === "add_to_epic") {
            var epic_status = "epic"
            info = {
                us_id: state.consolidatedToDo[index].us_id,
                view: epic_status,
                projectid: state.consolidatedToDo[index].project_id,
                taskId: state.consolidatedToDo[index].story_id,
                project_id: state.consolidatedToDo[index].project_id,
            }
        }
        else if (action === "current_add_to_sprint") {
            info = {
                project_id: state.consolidatedToDo[index].project_id,
                us_id: state.consolidatedToDo[index].us_id,
                projectid: state.consolidatedToDo[index].project_id,
                taskId: state.consolidatedToDo[index].story_id,
                // currentDate: data.currentDate,
                assignedTo: state.consolidatedToDo[index].assignedto,
                assign_to: state.consolidatedToDo[index].assignedTo,
                title: state.consolidatedToDo[index].story_title,
                sprintDesc: state.consolidatedToDo[index].sprint_desc,
                // device_id: state.consolidatedToDo[index].device_id,
                // player_id: state.consolidatedToDo[index].player_id,
                storyPoints: state.consolidatedToDo[index].story_points,
            }
        }
        else if (action === "Delete") {
            info = {
                projectName: state.consolidatedToDo[index].projectitle,
                title: state.consolidatedToDo[index].story_title,
                description: state.consolidatedToDo[index].story_desc,
                taskId: state.consolidatedToDo[index].story_id,
                moduleId: state.consolidatedToDo[index].sprint_id,
                action: action
            }
        } else if (action === "movetobacklog") {
            let message = `${MAINTASK} is blocked`;
            info = {
                taskId: state.consolidatedToDo[index].story_id,
                message: message,
                project_id: state.consolidatedToDo[index].project_id,
            }
        }
        setCardInfo(info)
    };

    const handleClose = () => {
        setOpen({ status: false, index: 0 })
        getConsolidatedTodo(dispatch, getUser.user);
        getPrivateTodo(dispatch, getUser.user);
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 })
    };
    const { action, redirect, status } = redirections;
    if (redirect) {
        if ((getRoleCount('roleCount') >= 1) && (action === "sprints" || action === "project")) {
            return status === ACTIVE_SPRINT ? <Redirect to={`/${MODULES}`} />
                : status === KANBAN ? <Redirect to={`/${KANBAN_NAME}`} /> : <Redirect to={`/${PENDING_SUBTASKS}`} />
        } else if ((getRoleCount('roleCount') >= 1) && action === "epic") {
            return <Redirect to={`/user/${MANAGEPROJECTS}`} />
        } else if ((getRoleCount('roleCount') >= 1)) {
            return <Redirect to={`/${MODULES}`} />
        } else {
            return <Redirect to='/' />
        }
    }

    return (
        <div className="container-scroller">
            <TopNavWithOutProject />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                    <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body" style={{ overflowX: "auto" }}>
                                    <div className="justify-content-between row">
                                        <h4 className="card-title" style={{ paddingLeft: 10, paddingTop: 10 }}>{CONSOLIDATED_TODO}</h4>
                                        <div className="d-flex justify-content-end mb-3 mt-2">
                                            <div class="input-group-prepend">
                                                <span class="text" style={{ color: 'black', marginTop: '3px', fontSize: '13px', paddingRight: 10 }}>Search: </span>
                                            </div>
                                            <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '35px' }}
                                                onChange={(event) => setSearchWord(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* eslint-disable-next-line */}
                                    {state.consolidatedToDo.length > 0 ? state.consolidatedToDo.filter((val) => {
                                        if (searchWord === "") {
                                            return val
                                        } else if (val.story_title.toLowerCase().includes(searchWord.toLowerCase()) || val.us_id.toLowerCase().includes(searchWord.toLowerCase())) {
                                            return val
                                        }
                                    }).map((todo, index) => {
                                        const { target_date, created_date, complete_status, project_name, sprint_target_date, us_id, story_title, project_id, active_status, priority_level, story_points, epic_name, story_id, sprint_id, assignedTo, kanban_status, role } = todo;
                                        let sprintActiveStatus = kanban_status === "1" && sprint_target_date === "0001-01-01" ? KANBAN : new Date(sprint_target_date) >= new Date(pst) ? ACTIVE_SPRINT : PENDING_SUBTASKS;
                                        let badgeColor = kanban_status === "1" && sprint_target_date === "0001-01-01" ? '#ADD8E6' : sprintActiveStatus === ACTIVE_SPRINT ? '#8F00FF' : '#E37066';
                                        const accessRole = (role_array[role] === SCRUM_MASTER || role_array[role] === PRODUCT_OWNER);//project/user story wise user role

                                        return (
                                            <tr>
                                                <td style={{border: '1px solid #e1e1e1'}}>
                                                    <div className="col-12 td1" style={(created_date > target_date && complete_status === 'pending' && target_date !== '0000-00-00') ? { backgroundColor: '#f58484' } : null}>
                                                        <div className="col-12" >
                                                            <div class="d-flex col-12">
                                                                <div class="p-2" onClick={(event) => handleOpen("taskInfo", index)}>
                                                                    <b style={{ width: '580px', cursor: 'pointer', paddingTop: 10 }} > <p style={{ width: 580, color: 'black', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</p> </b>
                                                                </div>
                                                                <div style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, width: '155px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</div>
                                                                <div style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</div>
                                                                <div style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</div>
                                                                <div style={{ backgroundColor: 'teal', cursor: 'pointer', color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, width: '25px', textAlign: 'center', height: 'fit-content' }} title={PRIORITY_LEVEL}>P:{priority_level}</div>
                                                                <div style={{ backgroundColor: '#6495ED', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, padding: '3px', borderRadius: '70px', width: '25px', textAlign: 'center', height: 'fit-content' }} title={STORY_POINTS}>{story_points}</div>
                                                                <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, padding: '5px', float: "right" }}>
                                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                    <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                                                                        <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0' }} />
                                                                    </a>
                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                        <div>
                                                                            {accessRole && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", index)}>{ADD_TO_EPIC}</button>}
                                                                            {accessRole && (sprintActiveStatus === PENDING_SUBTASKS) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("current_add_to_sprint", index)}>{ADD_TO_SPRINT}</button> : null}
                                                                            {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Move To Inprogress</button> : null}
                                                                            {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`)}>Move To Done</button> : null}
                                                                            {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#ADD8E6", color: 'white' }} onClick={() => handleOpen("movetobacklog", index)}>Move To {ROADBLOCKS}</button> : null}
                                                                            {accessRole && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modifyTask", index)}>{EDIT}</button>}
                                                                            {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button> */}
                                                                            <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewSubTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>
                                                                            {<button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", index)}>{NEWSUBTASK}</button>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ padding: '5px', marginLeft: '5px', marginTop: '2px', float: "right" }}>
                                                                    <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                                        {
                                                                            getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId)
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }) : null}
                                    {
                                        (open.action === "taskInfo" || open.action === "private_todo_taskInfo") ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleChatClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "modifyTask" ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "addSubtask" ? <AddSubTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "changeUserstoryStatus" && cardInfo != null ? (
                                            <ChangeUserstoryStatus open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} />)
                                            : null}
                                    {
                                        open.action === "Delete" ? <MainTaskDelete open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "Verify" ? <MainTaskVerify open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        (open.action === "add_to_epic" || open.action === "current_add_to_sprint") ? <AddToSprint open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "movetobacklog" ? <RoadBlock open={open} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="justify-content-between row">
                                        <h4 className="card-title" style={{ paddingLeft: 10, paddingTop: 20 }}>{PRIVATE_TODO}</h4>
                                        <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                                            <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={() => handleOpen("add")}> <img src="images/common/add.png" title={ADD_PRIVATE_TODO} alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{ADD_PRIVATE_TODO}</span></button>
                                            {
                                                open.action === "add" ? <AddPrivateToDo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                                /> : null
                                            }
                                        </div>
                                    </div>
                                    {/* eslint-disable-next-line */}
                                    {state.privateToDo.length > 0 ? state.privateToDo.filter((val) => {
                                        if (searchWord === "") {
                                            return val
                                        } else if (val.story_title.toLowerCase().includes(searchWord.toLowerCase())) {
                                            return val
                                        }
                                    }).map((todo, index) => {
                                        return (
                                            <div className="col-12">
                                                <div className="card col-12 ">
                                                    <div className="container-fluid col-12 row" >
                                                        <div class="d-flex col-12"  >
                                                            <div class="mr-auto p-2" onClick={(event) => handleOpen("private_todo_taskInfo", index)}>
                                                                <b style={{ width: '500px', cursor: 'pointer', paddingTop: 10, textTransform: 'capitalize' }} title={MAINTASKNAME}> {todo.personal_todo_status === "1" ? <p style={{ width: 500, color: 'black' }}>{todo.story_title}</p> : <del><p style={{ width: 500, color: 'black' }}>{todo.story_title}</p></del>} </b>
                                                            </div>
                                                            <p style={{ backgroundColor: 'teal', cursor: 'pointer', color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, width: '25px', textAlign: 'center' }} title={PRIORITY_LEVEL}>P:{todo.priority_level}</p>
                                                            <p style={{ backgroundColor: '#6495ED', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, padding: '3px', borderRadius: '70px', width: '25px', textAlign: 'center' }} title={STORY_POINTS}>{todo.story_points}</p>
                                                            <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 15, marginRight: 15, padding: '5px', marginTop: 5, float: "right" }}>
                                                                {/* eslint-disable-next-line */}
                                                                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                                    <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                                                </a>
                                                                {todo.personal_todo_status === "1" ?
                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                                        <div>
                                                                            <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }}
                                                                                onClick={(event) => handleOpen("addToProject", index, todo)}
                                                                            >Add to Project</button>
                                                                            <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#FFC100', color: 'white' }}
                                                                                onClick={(event) => handleOpen("modify", index, todo)}
                                                                            >Modify</button>
                                                                            {getUser.user.empId === todo.assigned_by ?
                                                                                <button className="dropdown-item badge badge-pill badge-danger text-center" style={{ backgroundColor: "#76C54E", color: 'white' }}
                                                                                    onClick={(event) => handleOpen("complete", index, todo)}
                                                                                >Complete</button>
                                                                                : ""}
                                                                            {getUser.user.empId === todo.assigned_by ?
                                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#FF4747", color: 'white' }}
                                                                                    onClick={(event) => handleOpen("delete", index, todo)}
                                                                                >Delete</button>
                                                                                : ""}
                                                                        </div>
                                                                    </div> :
                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                                        <div>
                                                                            {getUser.user.empId === todo.assigned_by ?
                                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#FF4747", color: 'white' }}
                                                                                    onClick={(event) => handleOpen("delete", index, todo)}
                                                                                >Delete</button>
                                                                                : ""}
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                        : null}
                                    {open.action === "addToProject" ?
                                        <AddToToDoProjects open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {(open.action === "delete" || open.action === "complete") ?
                                        <DeletePrivateToDo open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {open.action === "modify" ?
                                        <ModifyPrivateToDo open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}