import API from "../../../Common/Network/API";
import { isLoaded, isLoading, webProperties } from "../actions";
import { setToken } from '../../../Common/LocalStorage';
import store from '../../../Common/Store';
import { defaultWebProps } from "./defaultWebProps";


export async function getWebProps(state, dispatch) {

    dispatch(isLoading());
    store.dispatch(webProperties(JSON.stringify(defaultWebProps)))
    try {
      var response = await API.post("properties.php", {
        "action": "getWebProps",
        "app_name": window.location.origin,
        "properties": JSON.stringify(defaultWebProps),
        "prop_type": "web_props"
      }, {}, false);
  
      setToken('web_properties', response.data.properties)
      store.dispatch(webProperties(JSON.parse(response.data.properties))); //store the user information
    } catch (error) {
      // Alert('error',error.message);
    }
    dispatch(isLoaded());
  }