import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { setToken, getToken } from '../../../Common/LocalStorage';
import { getProps } from "../../../Authentication/LandingPage/Services/getProps";

export async function updateProps(state, dispatch, getUser, properties, image, appName) {

  var role_properties = JSON.parse(getToken('properties'))
  try {
    var response = await API.post("properties.php", {
      "crop": getUser.corp,
      "properties": JSON.stringify(properties),
      "action": "update",
      "image": image,
      "contributor": role_properties.CONTRIBUTOR,
      "product_owner": role_properties.PRODUCT_OWNER,
      "scrum_master": role_properties.SCRUM_MASTER,
      "app_name": appName,
      "prop_type": "app_props"
    }, {}, false);
    if (response.status === 'True') {
      Alert("success", 'Labels names are updated successfully,Make sure logout the application to the see the changes')
      setToken('properties', response?.data?.properties)
      // window.location.reload()
      getProps(state, dispatch)

    }
    else {
      Alert('warning', response.message);
    }
  } catch (error) {
    Alert('error', error.message);
  }
}
