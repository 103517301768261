import {  isLoading, isLoaded, setModifyTime} from "../action";
import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { getShifts } from "./getShifts";

//Modify Designation
export async function modifyTimeShift(state, dispatch) {
    if (state.start_time.value !== "" && state.end_time.value !== "" && state.zone.value !== "") {
        dispatch(isLoading());
        try {
            var response = await API.post("get_user_shifts.php", {
                action: 'modify_shift',
                start_time: state.start_time.value,
                end_time: state.end_time.value,
                time_zone: state.zone.value,
                ts_id:state.ts_id.value
            }, {}, false);
            if (response.status === "True") {
                Alert("success", "Shift Modified Successfully")
                getShifts(dispatch)
                dispatch(setModifyTime(""))
            } else {
                dispatch(setModifyTime(""))
            }
        } catch (error) {
            Alert('error', error.message);
        }
    } else {
        Alert("warning", "please give all details")
    }
    dispatch(isLoaded());
}