import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { setToken } from '../../../Common/LocalStorage';
import { getWebProps } from "../../../Authentication/LandingPage/Services/getWebProps";

export async function updateStandardWebProps(state, dispatch, getUser, properties, appName, webImages) {

    let prop1 = properties
    let prop2 = webImages
  
    let props = Object.assign(prop1, prop2)
  
    console.log(props)
  
    try {
      var response = await API.post("properties.php", {
        "crop": getUser.corp,
        "properties": JSON.stringify(props),
        "action": "webUpdate",
        "image": '',
        "app_name": appName,
        "prop_type": "web_props"
  
      }, {}, false);
      if (response.status === 'True') {
        Alert('success', 'Labels names are updated successfully,Make sure logout the application to the see the changes');
        setToken('web_properties', response.data.properties)
        getWebProps(state, dispatch)
  
        // window.location.reload()
      }
      else {
        Alert('success', response.message);
      }
    } catch (error) {
      Alert('error', error.message);
    }
  }
